import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import { Button, Layout, SectionTitle, SEO } from "components";
import { StaticImage } from "gatsby-plugin-image";

const fee = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title={t`config.nav.fee`}
        description={t`informations.paragraph_1`}
      />
      <article className="fee container">
        <SectionTitle title={t`config.title.fee`} />
        <section className="content-text">
          <p>{t("fee.paragraph_1")}</p>
          <p>{t("fee.paragraph_2")}</p>
          <p>
            {t("fee.paragraph_3")}{" "}
            <a className="fee__link" href={t("fee.link_url")} target="_blank">
              {t("fee.link_text")}
            </a>
          </p>
        </section>
        <section className="fee__payments-data">
          <h4>{t("fee.paymentsData")}</h4>
          <p>{t("fee.paymentsData_1")}</p>
          <p>{t("fee.paymentsData_2")}</p>
          <p>{t("fee.paymentsData_3")}</p>
          <p>{t("fee.paymentsData_4")}</p>
          <p>{t("fee.paymentsData_5")}</p>
          <p>
            <span>{t("fee.accountNumber")}</span>{" "}
            <span className="fee__text--bold">{t("fee.paymentsData_6")}</span>
          </p>
          <p>
            <span>{t("fee.transferTitle")}</span>{" "}
            <span className="fee__text--bold">{t("fee.paymentsData_7")}</span>
          </p>
        </section>
        <section className="fee__signin">
          <StaticImage
            className="fee__img"
            objectFit="contain"
            placeholder="blurred"
            src="../../images/fee/konfeo-logo.png"
            alt="konfeo"
          />
          <Button
            className="fee__btn"
            text="config.button.signin"
            to={t("fee.link_url")}
            newTab
          />
        </section>
      </article>
    </Layout>
  );
};

export default fee;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
